
$( document ).ready(function(){

    $("#submit-button").click(function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("html").offset().top
        }, 500);
    });
});

