$( document ).ready(function() {
    $('tr').on('click', function() {
        if($(this).attr('data-href').length > 0) {
            window.open($(this).attr('data-href'), '_blank').focus();
        }
    });

    $(".delete-btn").on("click", function(e) {
        e.stopPropagation();
        let deleteItemModal = new bootstrap.Modal(document.getElementById('deleteItemModal'))
        deleteItemModal.toggle();
        $(".btn-delete-item").attr("wire:click", "delete(" + $(this).attr('data-value') + ")");
    });
});
