if ($("#homePage").length > 0) {

    /*$( document ).ready(function(){
            videoInterval = setInterval(function () {$("#video_rack").get(0).play();}, 1000)
            setTimeout(function () {
                clearInterval(videoInterval)
            }, 10000);
    });*/

    // $("#video_rack").onended = function () {
    //     console.log('ended');
    // };
   /* $("#video_rack").on("ended", function() {
        // $("#background-still").removeClass("d-none");
        // $("#video_rack").addClass("d-none");
        // $("#video_rack").pause();
        // MODAL POP-UP
        if (!sessionStorage.modalShowed) {
            setTimeout(function () {
                $("#popUpModal").modal("show");
            }, 3000);
            sessionStorage.modalShowed = true;
        }
    });*/

    /* Modales */

/*    function showContestModal(){
        setTimeout(function () {
            $("#contestmodal").modal("show");
        }, 3000);
        sessionStorage.modalShowedContest3 = true;
    }*/
    //
    // if (!sessionStorage.modalShowed) {
    //     setTimeout(function () {
    //         $("#popUpModal").modal("show");
    //
    //         setTimeout(function () {
    //             showContestModal();
    //         }, 60000);
    //
    //     }, 3000);
    //     sessionStorage.modalShowed = true;
    // } else if (!sessionStorage.modalShowedContest3) {
    //     showContestModal();
    // }

/*
    if (!sessionStorage.modalShowedContest3) {
            showContestModal();
        }
*/

    var swiper = new Swiper(".shop-cards", {
        slidesPerView: 1,
        spaceBetween: 40,
        roundLengths: true,
        loop: false,
        breakpoints: {
            1200: {
                slidesPerView: 3,
                spaceBetweenSlides: 20,
            },
            768: {
                slidesPerView: 1,
                spaceBetweenSlides: 40,
            },
        },
    });

    var swiper = new Swiper(".swiper-container", {
        slidesPerView: "auto",
        spaceBetween: 60,
        centeredSlides: true,
        roundLengths: true,
        loop: true,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
            waitForTransition: false,
        },
    });

    /*var swiper2 = new Swiper(".swiper-container-img", {
        slidesPerView: "auto",
        spaceBetween: 60,
        centeredSlides: true,
        roundLengths: true,
        effect: "fade",
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            waitForTransition: false,
        },
        scrollbar: {
            el: ".swiper-scrollbar2",
            hide: true,
        },
    });
    //var swiper2 = new SwiperDemo();

    $(".imgbutton").on("click", function () {
        $(this).siblings(".imgs").addClass("visible");
        $(this).addClass("d-none");

        setTimeout(function () {
            topCalc(".third");
            topCalc(".first");
        }, 400);
    });

    topCalc(".third");
    topCalc(".first");

    function topCalc(variable) {
        let top = window.innerHeight - $(variable).outerHeight();
        $(variable).css("top", top > 190 ? 190 : top);
    }

    let first_spalsh = false;
    let second_spalsh = false;
    let third_spalsh = false;
    $(window).on("scroll", function () {
        if (
            $(window).scrollTop() + $(window).height() - 500 >=
                $("#splash-container").offset().top &&
            !first_spalsh
        ) {
            first_spalsh = true;
            $(".splash-block:nth-child(1)").addClass("visible");
            if (
                $(window).scrollTop() + $(window).height() - 500 >=
                $(".splash-block:nth-child(2)").offset().top
            ) {
                second_spalsh = true;
                $(".splash-block:nth-child(2)").addClass("visible");
                if (
                    $(window).scrollTop() + $(window).height() - 500 >=
                    $(".splash-block:nth-child(3)").offset().top
                ) {
                    third_spalsh = true;
                    $(".splash-block:nth-child(3)").addClass("visible");
                }
            }
        }

        if (
            $(window).scrollTop() + $(window).height() - 500 >=
                $(".splash-block:nth-child(2)").offset().top &&
            !second_spalsh
        ) {
            second_spalsh = true;
            $(".splash-block:nth-child(2)").addClass("visible");
            if (
                $(window).scrollTop() + $(window).height() - 500 >=
                $(".splash-block:nth-child(3)").offset().top
            ) {
                setTimeout(() => {
                    $(".splash-block:nth-child(3)").addClass("visible");
                    third_spalsh = true;
                }, 1000);
            }
        }

        if (
            $(window).scrollTop() + $(window).height() - 500 >=
                $(".splash-block:nth-child(3)").offset().top &&
            !third_spalsh
        ) {
            third_spalsh = true;
            $(".splash-block:nth-child(3)").addClass("visible");
        }
    });*/
}

//Button to scroll automatically up
$(window).on("scroll", function () {
    if ($(window).scrollTop() > 2100) {
        $(".go-up").removeClass("hidden");
    } else {
        $(".go-up").addClass("hidden");
    }
});

$(".go-up").on("click", function (e) {
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "300");
});
//
/*$(".show-bottom").on("mouseover", function () {
    $(".nav-bottom-block").addClass("open");
});

$(".navbar").on("mouseleave", function () {
    $(".nav-bottom-block").removeClass("open");
});*/

if ($("#Masterclass").length > 0) {
    $(".photo").on("click", function () {
        $("body").addClass("no-scroll");
    });

    $(".close").on("click", function () {
        $("body").removeClass("no-scroll");
    });
}

// if (sessionStorage.getItem("visited")) {
//     $("#loader").addClass("d-none");
//     $(".curtain-container").addClass("d-none lowerZindex");
// }
//
// if (!sessionStorage.getItem("visited")) {
//
//     $("#loader").removeClass("d-none");
//     $( document ).ready(function(){
//         $(".curtain_panel-left").addClass("slideIntoLeft")
//         $(".curtain_panel-right").addClass("slideIntoRight");
//         setTimeout(function(){
//             $(".loader-block").css("opacity","1");
//         },3000);
//     });}
//
//
//     $(".btn-loader").on("click", function () {
//
//         if($("#homePage").length > 0){
//             $("#navbar").addClass("d-none");
//             $("body").addClass("no-scroll");
//             $("#video_rack").get(0).play();
//         }
//         $("#loader").addClass("d-none");
//         // $("body").removeClass("no-scroll");
//         $(".curtain-container").addClass("d-none lowerZindex");
//         sessionStorage.setItem("visited", true);
//
//
//         setTimeout(function () {
//             $("body").removeClass("no-scroll");
//             $("#navbar").removeClass("d-none");
//             clearInterval(videoInterval);
//         }, 11000);
//     });

$('body').addClass('no-scroll');

setTimeout(function () {
    $('#loader').css('opacity', 0);
}, 1200);

setTimeout(function () {
    $('#loader').addClass('d-none');
    $('body').removeClass('no-scroll');
}, 1500);



$(document).on("submit", "#newsletterForm", function () {
    $.get("/newsletter/" + $("#newsletterEmail").val());
    return false;
});

$(document).on('click', '.page-link-scroll-to-top', function (e) {
    $("html, body").animate({ scrollTop: 0 });
    return false;
});
