require("./bootstrap");
require("./home");
//require('./home2');
require("./webinars");
require("./journal");
require("./admin");
require("./search");
require("./team")
require("./about-us")
require("./dashboard")
