if ($("#journalShow").length > 0) {
    let target       = $(".fullviewimg");
    let targetHeight = target.outerHeight();

    $(document).scroll(function (e) {
        let scrollPercent = (targetHeight - window.scrollY) / (targetHeight / 1.3);
        if (scrollPercent >= 0) {
            target.css('opacity', scrollPercent);
        }
    });

    $(window).on("scroll", function () {
        if ($(window).scrollTop() > 650) {
            $(".journalTitle").addClass("centered");
        }
    });
}
