if ($("#about-us").length > 0) {
    $(window).on("scroll", function () {
        var scroll = $(window).scrollTop();
        if (scroll > 0) {
            $("#h2-title").addClass('h2-active')
        };
        if (scroll >= 500) {
            $("#h2-title2").addClass('h2-active')
            // $("#initiative-title").addClass('h2-titles-en-right')
        };
        if(scroll >= 1300){
            $("#h2-title3").addClass('h2-active')
            // $("#link-title").addClass('h2-titles-en-right')
        };
    });
}
