if ($("#team").length > 0) {
    var exampleModal = document.getElementById('myModal')
    exampleModal.addEventListener('show.bs.modal', function (event) {
        var button = event.relatedTarget;
        let mail = button.getAttribute(('data-bs-contact'));
        let maildirection = "mailto:" + mail;
        //
        $('.modal-title').html(button.getAttribute('data-bs-name'));
        $('.modal-body').html(button.getAttribute('data-bs-descrip'));
        $('.modal-details').html(button.getAttribute('data-bs-details'));
        $('.modal-email').html(button.getAttribute('data-bs-contact'));
        $('.modal-contact').attr('href',maildirection);
        $('.modal-contact-linked').attr('href',button.getAttribute('data-linked'));
        $('.modal-img img').attr('src', button.getAttribute('data-bs-img'));
    })
}
